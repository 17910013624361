import React from 'react';
import { usePrivateFunction } from '../../Security/usePrivateFunction';
import { archiveProperty } from '@a1-ict/fsbo/propertySlice/requests';
import { fetchMyProperties } from '@a1-ict/fsbo/userSlice';
import { useDispatch } from 'react-redux';
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { toast } from 'react-toastify';

const ArchiveProperty = ({ show, setShowArchive, propertyId }) => {
    const { secure } = usePrivateFunction();
    const dispatch = useDispatch();


    const handleArchive = (propertyId) => {
        secure(async () => {
            const result = await dispatch(archiveProperty({ id: propertyId }));

            if (result?.payload?.ok) {
                dispatch(fetchMyProperties({ queryParams: {} }));
                toast.success('Успешно архивиране на обява!', {
                    position: toast.POSITION.BOTTOM_CENTER,
                });
                setShowArchive(false);
            } else {
                toast.error('Неуспешно архивиране на обява!', {
                    position: toast.POSITION.BOTTOM_CENTER,
                });
                setShowArchive(false);
            }
        });
    };

    return (
        <Modal isOpen={show} toggle={() => setShowArchive(!show)} size="md">
            <ModalHeader toggle={() => setShowArchive(!show)}>Архивиране на обява</ModalHeader>
            <ModalBody className="p-0 d-flex flex-column justify-content-center">
                <Row className="justify-content-center my-3">
                    <Col xs={12} sm={10} className="mb-2 d-flex flex-column justify-content-center px-4">
                        При архивиране на обява, платените суми не се възстановяват. Сигурен ли сте, че искате да архивирате обявата?
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <button className="btn animated px-3 py-2" onClick={() => handleArchive(propertyId)}>
                    <span className="">Архивирай</span>
                </button>
                <Button color="secondary" onClick={() => setShowArchive(false)}>
                    Отказ
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default ArchiveProperty;
