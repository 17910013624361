import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from 'reactstrap';
import ePay from '../../images/epay-large.png';
import easyPay from '../../images/easy-p-large.png';
import { FaCheck } from '@react-icons/all-files/fa/FaCheck';
import { FaMoneyCheckAlt } from '@react-icons/all-files/fa/FaMoneyCheckAlt';
import { useDialog } from '@a1-ict/core/dialogSlice/useDialog';
import { fetchPromotePackages } from '@a1-ict/fsbo/paymentSlice';
import { selectPromotePackages } from '@a1-ict/fsbo/paymentSlice/selectors';
import { useTransition, animated } from 'react-spring';
import { selectHasInvoiceDetails, selectMyDetails } from '@a1-ict/fsbo/userSlice/selectors';
import { usePayments } from './usePayments';
import { hasInvoiceDetails } from '@a1-ict/fsbo/userSlice/requests';
import InvoiceDetails from '../InvoiceDetails';

const BuyPackageModal = ({
  dialogName = 'buyPackage',
  subtitle = null,
  onPaymentAttempted = () => {},
  setSelectedSlotDetails = () => {},
  selectedSlotDetails = {},
}) => {
  const { isOpen, toggle, close } = useDialog(dialogName);
  const dispatch = useDispatch();
  const packages = useSelector(selectPromotePackages);
  const myDetails = useSelector(selectMyDetails);
  const { paymentTypes, postPaymentData } = usePayments();

  const [showPayment, setShowPayment] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const invoiceDetailsFilled = useSelector(selectHasInvoiceDetails);

  useEffect(() => {
    if (isOpen) dispatch(fetchPromotePackages({ packageType: 'propertyslot' }));
    else {
      setSelectedSlotDetails({});
      setShowPayment(false);
      setSelectedPackage(null);
    }
  }, [isOpen]);

  useEffect(() => {
    if (selectedSlotDetails.packageId) {
      setShowPayment(true);
      setSelectedPackage(selectedSlotDetails.packageId);
    }
  }, [selectedSlotDetails.packageId]);

  useEffect(() => {
    dispatch(hasInvoiceDetails());
  }, []);

  let transition = useTransition(showPayment, {
    from: { height: 0, width: 0 },
    enter: {
      height: 300,
      width: 300,
    },
    leave: { height: 0, width: 0 },
  });

  const handleEpayPayment = (selectedPaymentType) => {
    if (selectedSlotDetails.packageId) {
      postPaymentData(selectedPaymentType, {
        package_id: selectedSlotDetails.packageId,
        user_property_slot_package_id: selectedSlotDetails.id,
      });
    } else {
      postPaymentData(selectedPaymentType, {
        package_id: selectedPackage,
      });
    }
  };

  const handleBalancePayment = async (selectedPaymentType) => {
    let result;
    if (selectedSlotDetails.packageId) {
      result = await postPaymentData(
        selectedPaymentType,
        {
          package_id: selectedSlotDetails.packageId,
          user_property_slot_package_id: selectedSlotDetails.id,
        },
        true
      );
    } else {
      result = await postPaymentData(
        selectedPaymentType,
        {
          package_id: selectedPackage,
        },
        true
      );
    }

    const message = {
      error: result?.error?.message ?? 'Проблем с плащането!',
      success: selectedSlotDetails.packageId
        ? `Успешно удължихте ${selectedSlotDetails.packageName}! Пакетите ще се обновят след няколко секунди.`
        : 'Успешно закупихте пакет за допълнителни обикновени обяви! Пакетите ще се обновят след няколко секунди.',
    };

    onPaymentAttempted(result, 'balance', message);
    if (result?.payload?.ok) close();
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} scrollable={true}>
      <ModalHeader toggle={toggle}>Допълнителни пакети</ModalHeader>
      <ModalBody className="">
        <p className="text-center">{subtitle}</p>
        <div className="d-flex flex-column  text-center wallet_section">
          {invoiceDetailsFilled ? (
            <>
              {!selectedSlotDetails.id ? (
                <>
                  <h6 className="h5">Изберете един от нашите пакети:</h6>
                  <div className="wallet_section__slot_packages">
                    {packages?.map((pack) => {
                      return (
                        <div
                          key={pack.id}
                          className={`box-default d-flex flex-column p-3 justify-content-start text-left mb-2 ${
                            selectedPackage === pack.id ? ' active' : ''
                          }`}
                          onClick={() => {
                            setSelectedPackage(pack.id);
                            if (!!invoiceDetailsFilled) setShowPayment(true);
                          }}
                        >
                          <h4 className="h4  mb-0">{pack.formatted_price}лв.</h4>

                          <ul style={{ listStyle: 'none' }} className="my-3 pl-0">
                            <li>
                              <FaCheck className="mr-2 color-primary"></FaCheck> {pack.name}
                            </li>

                            <li>
                              <FaCheck className="mr-2 color-primary"></FaCheck> Пакетът е валиден
                              за {pack.validity_in_days} дни
                            </li>
                          </ul>
                        </div>
                      );
                    })}
                    <p className="mt-2">* Посочените цени са в лева с ДДС</p>
                  </div>
                </>
              ) : (
                <div>
                  <h6>
                    {selectedSlotDetails.packageName} - {selectedSlotDetails.packagePrice}
                  </h6>
                </div>
              )}
              {transition(
                (style, showPayment) =>
                  showPayment && (
                    <animated.div style={style} className="m-auto">
                      <Container fluid className="mt-4 text-dark">
                        <h6 className="h5">Метод на плащане</h6>
                        <button
                          onClick={() => handleBalancePayment(paymentTypes.balance)}
                          className="w-100 box-default p-1 py-3  my-2 mx-0"
                        >
                          <div className="d-flex">
                            <Col xs={2}>
                              <FaMoneyCheckAlt color="#8593A9"></FaMoneyCheckAlt>
                            </Col>
                            <Col xs={10} className="flex-row ">
                              Наличен баланс: {myDetails?.current_balance.toFixed(2)} кредити
                            </Col>
                          </div>
                        </button>
                        {/* <button
                  
                    onClick={() => handleEpayPayment(paymentTypes.creditCard)}
                    className="w-100 box-default p-1 py-3  my-2 mx-0"
                  >
                    <div className="d-flex">
                      <Col xs={2}>
                        <FaRegCreditCard color="#8593A9"></FaRegCreditCard>
                      </Col>
                      <Col xs={10} className="flex-row ">
                        Дебитна/Кредитна карта
                      </Col>
                    </div>
                  </button> */}

                        <button
                          onClick={() => handleEpayPayment(paymentTypes.epay)}
                          className="w-100 box-default p-1 py-3  my-2 mx-0"
                        >
                          <div className="d-flex">
                            <Col xs={3}>
                              <img src={ePay} alt="ePay" height="12px"></img>
                              <img src={easyPay} alt="easyPay" height="16px"></img>
                            </Col>
                            <Col xs={9} className="flex-row m-auto">
                              ePay/EasyPay
                            </Col>
                          </div>
                        </button>
                      </Container>
                    </animated.div>
                  )
              )}
            </>
          ) : (
            <InvoiceDetails />
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={close}>
          Отказ
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default BuyPackageModal;
