import React from 'react';

const CardsList = ({ data, columns }) => {
  const renderCell = (item, column) => {
    if (column.content)
      return (
        <div
          className={column.wrapperClass ? column.wrapperClass : ''}
          key={createKey(item, column)}
        >
          {column.label && (
            <div>
              <b>{column.label}</b>
            </div>
          )}
          {column.content(item)}
        </div>
      );
  };

  const createKey = (item, column) => {
    return item.id + column.label;
  };

  const list = data.map((item) => {
    return (
      <div key={item.id} className="d-flex flex-column cardslist_card radiused my-2">
        <div className="d-flex flex-sm-row justify-content-between align-items-start  p-xs-1 p-sm-2 ">
          {columns.map((column) => {
            return renderCell(item, column);
          })}
        </div>
      </div>
    );
  });
  return <div className="d-flex flex-column cardslist">{list}</div>;
};

export default CardsList;
