import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import Icon from '../Common/Icons';

import { useBreakpoint } from '@a1-ict/gatsby/utils/breakpoint';
import { thousandsSeparator } from './helpers';

const MinifiedProperty = ({
  property,
  color = 'white',
  renderDetails = false,
  extraClass = '',
}) => {
  const breakpoints = useBreakpoint();
  const {
    name,
    title,
    price,
    rooms,
    bathrooms,
    floor,
    building_floors,
    size,
    yard_size,
    id,
    images,
  } = property;

  return (
    <div
      className={`min_property min_property_container align-items-stretch d-flex flex-row ${extraClass} mr-2`}
    >
      {/* <StaticImage
        src="../../images/property.jpg"
        alt="A dinosaur"
        layout="fixed"
        placeholder="dominantColor"
        imgClassName="min_property_image"
        className="min_property_image_wrapper mr-2"
        height={80}
        width={115}
      /> */}
      <img
        className="min_property_image_wrapper mr-2"
        height={80}
        width={115}
        src={images && `${process.env.GATSBY_BACKEND}${images[0]?.url}`}
        alt={name || title}
        layout="fixed"
      />

      <div className="min_property_info d-flex flex-column justify-content-between">
        <div className="min_property_info__title_price">
          <h6 style={{ color }} className="pt-1 min_property_info__title mb-1">
            <Link to={`/property/${id}`} className="animated">
              {/* <Link to={`/${property.name}`} className="animated"></Link> */} {name || title}{' '}
            </Link>
          </h6>

          <span style={{ color: color }} className="min_property_info__price">
            {property.price
              ? thousandsSeparator(property.price) + ' EUR'
              : property.square_price
              ? thousandsSeparator(property.square_price) + ' EUR/кв.м'
              : 'по договаряне'}
          </span>
        </div>
        <ul className="min_property_info__details d-flex">
          {renderDetails && !breakpoints.xxs && (
            <>
              {floor && (
                <li key={floor} className="mr-4 mb-2">
                  <Icon icon="stairs" size="xs" />
                  <span className="ml-1">
                    Етаж: {floor} от {building_floors}
                  </span>
                </li>
              )}
              {rooms && (
                <li key={rooms} className="mr-4 mb-2">
                  <Icon icon="rooms" size="xs" />
                  <span className="ml-1">
                    {rooms} {rooms == 1 ? 'стая' : 'стаи'}
                  </span>
                </li>
              )}
              {bathrooms && (
                <li key={bathrooms} className="mr-4 mb-2 ">
                  <Icon icon="bathrooms" size="xs" />
                  <span className="ml-1">
                    {bathrooms} {bathrooms == 1 ? 'баня' : 'бани'}
                  </span>
                </li>
              )}
              {size && (
                <li key={size} className="mr-4  mb-2">
                  <Icon icon="size" size="xs" />
                  <span className="ml-1"> {size} кв.м.</span>
                </li>
              )}
              {yard_size && (
                <li key={yard_size} className="mr-4  mb-2">
                  <Icon icon="size" size="xs" />
                  <span className="ml-1"> {yard_size} кв.м. двор</span>
                </li>
              )}
            </>
          )}
        </ul>
      </div>
    </div>
  );
};

export default MinifiedProperty;
