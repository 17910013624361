import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import PromoteProperty from '../../components/PromoteProperty';

import { useDialog } from '@a1-ict/core/dialogSlice/useDialog';

const PromotePropertyModal = ({
  propertyId,
  property = {},
  isBuilding,
  dialogName = 'promote',
  subtitle = null,
  resetAction = () => { },
  size = 'md',
  onPaymentSuccess,
}) => {
  const { isOpen, toggle } = useDialog(dialogName);

  const handleToggle = () => {
    resetAction();
    toggle();
  };

  const onPaymentAttempted = (result, paymentToolUsed = 'promote', message) => {
    onPaymentSuccess(result, paymentToolUsed, message);
    handleToggle();
  };

  return (
    <Modal isOpen={isOpen} toggle={handleToggle} scrollable={true} size={size}>
      <ModalHeader toggle={handleToggle}>Обновяване/Промотиране</ModalHeader>
      <ModalBody>
        {subtitle}
        <PromoteProperty
          property={property}
          newProperty={false}
          isBuilding={isBuilding}
          propertyId={propertyId}
          onPaymentAttempted={onPaymentAttempted}
        ></PromoteProperty>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={() => handleToggle()}>
          Затвори
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default PromotePropertyModal;
