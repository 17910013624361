
import React from 'react'
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
} from 'reactstrap';
import { useDialog } from '@a1-ict/core/dialogSlice/useDialog';
import InvoiceDetails from '../InvoiceDetails';

const InvoiceDetailsModal = ({ dialogName = 'invoiceDetails' }) => {
    const { isOpen, toggle, close } = useDialog(dialogName);

    return (
        <Modal isOpen={isOpen} toggle={toggle} scrollable={true}>
            <ModalHeader toggle={toggle}>Моля попълнете данните за фактура</ModalHeader>
            <ModalBody className="">
                <InvoiceDetails />
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={close}>
                    Отказ
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default InvoiceDetailsModal