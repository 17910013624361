import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { useTransition, animated } from 'react-spring';
import { useDispatch, useSelector } from 'react-redux';

import { fetchPromotePackages } from '@a1-ict/fsbo/paymentSlice';
import { selectPromotePackages } from '@a1-ict/fsbo/paymentSlice/selectors';

import { FaMoneyCheckAlt } from '@react-icons/all-files/fa/FaMoneyCheckAlt';

import ePay from '../images/epay-large.png';
import easyPay from '../images/easy-p-large.png';
import { usePayments } from './Payment/usePayments';
import { selectHasInvoiceDetails, selectMyDetails } from '@a1-ict/fsbo/userSlice/selectors';
import { Link } from 'gatsby';
import { hasInvoiceDetails } from '@a1-ict/fsbo/userSlice/requests';
import { useDialog } from '@a1-ict/core/dialogSlice/useDialog';
import InvoiceDetailsModal from './Payment/InvoiceDetailsModal';

const buttonStyle = {
  goldBtnStyle: {
    background: 'linear-gradient(#FFD500, #F6E58B, #CBA901)',
  },
  silverBtnStyle: {
    background: 'linear-gradient(#D4D2D2, #F3F2F2, #B2B2B2)',
  },
  freeBtnStyle: {
    border: '1px solid #e2e2e2',
  },
};

const PromoteProperty = ({
  isBuilding = false,
  className = '',
  propertyId,
  newProperty = true,
  isLimitExceeded,
  property = {},
  onPaymentAttempted = () => {},
}) => {
  const [showPayment, setShowPayment] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);

  const invoiceDetailsModal = useDialog('invoiceDetails');
  const dispatch = useDispatch();

  const myDetails = useSelector(selectMyDetails);

  const packages = useSelector(selectPromotePackages);
  const invoiceDetailsFilled = useSelector(selectHasInvoiceDetails);

  const { paymentTypes, postPaymentData } = usePayments();

  useEffect(() => {
    if (invoiceDetailsFilled) {
      invoiceDetailsModal.close();
      if (selectedPackage) setShowPayment(true);
    }
  }, [invoiceDetailsFilled]);

  useEffect(async () => {
    if (!isLimitExceeded)
      dispatch(
        fetchPromotePackages({
          packageType: isBuilding ? 'building' : 'singular',
          propertyId: propertyId,
        })
      );
    await dispatch(hasInvoiceDetails());
  }, []);

  let transition = useTransition(showPayment, {
    from: { height: 0, width: 0 },
    enter: {
      height: 300,
      width: 300,
    },
    leave: { height: 0, width: 0 },
  });

  const handleEpayPayment = (selectedPaymentType) => {
    postPaymentData(selectedPaymentType, {
      package_id: selectedPackage.id,
      property_id: propertyId,
    });
  };

  const handleBalancePayment = async (selectedPaymentType, freePack = null) => {
    const result = await postPaymentData(
      selectedPaymentType,
      {
        package_id: freePack ? freePack : selectedPackage.id,
        property_id: propertyId,
      },
      true
    );

    const message = {
      error: result?.error?.message ?? 'Проблем с плащането!',
      success: isBuilding
        ? 'Успешно заплатихте от наличния Ви баланс! Благодарим Ви! Статусът на обявате ще се обнови след няколко секунди.'
        : freePack
        ? 'Успешно обновихте обявата! Благодарим Ви! Статусът й ще се промени след няколко секунди!'
        : 'Успешно промотирахте от наличния Ви баланс! Благодарим Ви! Статусът на обявате ще се обнови след няколко секунди.',
    };
    onPaymentAttempted(result, 'balance', message);
  };

  return (
    <Container fluid="md" className={className}>
      <InvoiceDetailsModal />
      <div className="bg-white p-2 d-flex flex-column align-items-center promote_packages">
        {packages.length > 0 && (
          <div className={`d-flex flex-column`}>
            <div className="d-flex justify-content-center flex-wrap">
              {packages.map((pack) => {
                if (newProperty && pack.free === true) {
                  return;
                } else {
                  return (
                    <button
                      key={pack.id}
                      style={
                        pack.free
                          ? buttonStyle.freeBtnStyle
                          : pack.golden
                          ? buttonStyle.goldBtnStyle
                          : buttonStyle.silverBtnStyle
                      }
                      className={`btn m-1 promote_packages__button${
                        selectedPackage?.id === pack.id ? '_active' : ' '
                      }`}
                      onClick={() => {
                        if (pack.free) {
                          handleBalancePayment(paymentTypes.balance, pack.id);
                        } else {
                          setSelectedPackage(pack);
                          if (!!invoiceDetailsFilled) {
                            setShowPayment(true);
                          } else {
                            invoiceDetailsModal.open();
                          }
                        }
                      }}
                    >
                      <div className="d-flex flex-column align-items-center justify-content-center">
                        <span className="mb-2">{pack.name}</span>
                        <span className="">{pack.formatted_price} лв</span>
                      </div>
                    </button>
                  );
                }
              })}
            </div>
            <span className="mt-2 text-small text-center">* Посочените цени са в лева с ДДС. </span>
            {property.is_golden && (
              <span className="text-small text-center">
                * При избор и заплащане на една от горепосочените опции ще удължите срока на обявата
                си.{' '}
              </span>
            )}
            {property.is_silver && (
              <span className="text-small text-center">
                * При избор и заплащане на Silver статус ще удължите срока на обявата си.{' '}
              </span>
            )}
            {property.is_silver && (
              <span className="text-small text-center">
                * Ако Вашата обява е със Silver статус и я промотирате като Gold, средствата не се
                възстановяват.{' '}
              </span>
            )}
            {property.is_building && (
              <span className="text-small text-center">
                * При избор и заплащане на горепосочената опция ще удължите срока на обявата си.
              </span>
            )}
          </div>
        )}

        {transition(
          (style, showPayment) =>
            showPayment && (
              <animated.div style={style}>
                <Container fluid className="mt-2 ">
                  {myDetails?.current_balance > selectedPackage.formatted_price ? (
                    <button
                      onClick={() => handleBalancePayment(paymentTypes.balance)}
                      className="w-100 box-default p-1 py-3  my-2 mx-0"
                    >
                      <div className="d-flex">
                        <Col xs={2}>
                          <FaMoneyCheckAlt color="#8593A9"></FaMoneyCheckAlt>
                        </Col>
                        <Col xs={10} className="flex-row ">
                          Наличен баланс: {myDetails?.current_balance.toFixed(2)} кредити
                        </Col>
                      </div>
                    </button>
                  ) : (
                    <p>
                      Недостатъчен наличен баланс! Имате възможност да заредите портфейла си от{' '}
                      <Link to={`/dashboard/wallet`} className="color-primary mx-1">
                        "Меню Портфейл"
                      </Link>
                      във Вашия профил!
                    </p>
                  )}

                  {/* <button
                  
                    onClick={() => handleEpayPayment(paymentTypes.creditCard)}
                    className="w-100 box-default p-1 py-3  my-2 mx-0"
                  >
                    <div className="d-flex">
                      <Col xs={2}>
                        <FaRegCreditCard color="#8593A9"></FaRegCreditCard>
                      </Col>
                      <Col xs={10} className="flex-row ">
                        Дебитна/Кредитна карта
                      </Col>
                    </div>
                  </button> */}

                  <button
                    onClick={() => handleEpayPayment(paymentTypes.epay)}
                    className="w-100 box-default p-1 py-3  my-2 mx-0"
                  >
                    <div className="d-flex">
                      <Col xs={3}>
                        <img src={ePay} alt="ePay" height="12px"></img>
                        <img src={easyPay} alt="easyPay" height="16px"></img>
                      </Col>
                      <Col xs={9} className="flex-row ">
                        ePay/EasyPay
                      </Col>
                    </div>
                  </button>

                  {/* <button onClick={() => {}} className="w-100 box-default p-1 py-3  my-2 mx-0">
                    <div className="d-flex">
                      <Col xs={3}>
                        <img src={easyPay} alt="easyPay" height="16px"></img>
                      </Col>
                      <Col xs={9} className="flex-row ">
                        Easypay
                      </Col>
                    </div>
                  </button> */}
                </Container>
              </animated.div>
            )
        )}
      </div>
    </Container>
  );
};

export default PromoteProperty;
