import React from 'react';
import { thousandsSeparator } from '../helpers';
import MinifiedProperty from '../MiniProperty';
import DropDown from './Dropdown';
import { AiFillEye } from '@react-icons/all-files/ai/AiFillEye';

const getStatus = (status) => {
  switch (status) {
    case 'Active':
      return 'Активна';
    case 'Archive':
      return 'Архивирана';
    default:
      return status;
  }
};

const getPromoted = (row, isBuilding, isPaidBuilding) => {
  if (isBuilding) {
    return isPaidBuilding ? 'Платена' : 'Безплатна';
  }
  if (row.is_golden) return 'Gold';
  if (row.is_silver) return 'Silver';
  return 'Обикновена';
};

export const tableData = (
  dataType,
  handlePromote,
  handleDelete,
  preparePrint,
  handleArchive,
  promoteModal
) => {
  const cardColumns = [
    // {
    //   label: null,
    //   content: (row) => <MinifiedProperty renderDetails={true} property={row} color="black" />,
    // },
    {
      label: '#',
      content: (row) => (
        <div className="d-flex flex-column">
          <div className="">{row.id}</div>
        </div>
      ),
    },
    {
      label: 'Статус',
      wrapperClass: 'w-25',
      content: (row) => {
        return (
          <div className="d-flex flex-column">
            <div className="">{getStatus(row.status)}</div>
            <div className="">{getPromoted(row, row.is_building, row.is_paid_building)}</div>
          </div>
        );
      },
    },
    {
      label: 'Валидна до',
      content: (row) => (
        <div className="d-flex flex-column">
          <div className="">{row.active_until || ''}</div>
        </div>
      ),
    },
    {
      label: <AiFillEye></AiFillEye>,
      content: (row) => <span>{row.views_count}</span>,
    },
    {
      label: null,
      content: (row) => (
        <DropDown
          id={row.id}
          status={row.status}
          isPromoted={row.is_golden || row.is_silver}
          handlePromoteModal={promoteModal}
          preparePrint={preparePrint}
          handleDelete={handleDelete}
          handleArchive={handleArchive}
          handlePromote={handlePromote}
          property={row}
        />
      ),
    },
  ];

  const tableColumns = [
    {
      label: 'Номер',
      content: (row) => (
        <div className="d-flex flex-column">
          <div className="">{row.id}</div>
        </div>
      ),
    },
    {
      label: 'Имот, Категория, Адрес',
      content: (row) => (
        <div className="d-flex flex-column">
          <div className="">
            <div
              className="property_card_image"
              style={
                row.images &&
                row.images[0] && {
                  backgroundImage: `url(${process.env.GATSBY_BACKEND}${row.images[0].url})`,
                  width: '104px',
                  height: '80px',
                }
              }
            />

            <div className="">{row.category}</div>
          </div>
          <div>
            {row.street && ` ${row.street} ${row.street_number},`}{' '}
            {row.neighborhood && ` ${row.neighborhood},`} {row.block && `бл. ${row.block},`}{' '}
            {row.city && `${row.city}`}
          </div>
        </div>
      ),
    },
    {
      label: 'Валидна до',
      content: (row) => (
        <div className="d-flex flex-column">
          <div className="">{row.active_until || ''}</div>
        </div>
      ),
    },
    {
      label: 'Цена',
      content: (row) => (
        <div>
          {row.price
            ? thousandsSeparator(row.price) + ' EUR'
            : row.square_price
              ? thousandsSeparator(row.square_price) + ' EUR/кв.м.'
              : ''}
        </div>
      ),
    },
    {
      label: 'Статус и Промотиране',
      content: (row) => {
        return (
          getStatus(row.status) + ', ' + getPromoted(row, row.is_building, row.is_paid_building)
        );
      },
    },

    { label: 'Видяна', path: 'views_count' },
    {
      label: 'Действия',
      content: (row) => (
        <DropDown
          id={row.id}
          status={row.status}
          isPromoted={row.is_golden || row.is_silver}
          handlePromoteModal={promoteModal}
          handleDelete={handleDelete}
          preparePrint={preparePrint}
          handlePromote={handlePromote}
          handleArchive={handleArchive}
          property={row}
        />
      ),
    },
  ];
  return dataType === 'card' ? cardColumns : tableColumns;
};
