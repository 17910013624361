import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from '@reach/router';

import { useDispatch, useSelector } from 'react-redux';

import { useReactToPrint } from 'react-to-print';

import { toast } from 'react-toastify';

import { Row, Col } from 'reactstrap';

import DashboardLayout from '../../components/layouts/DashboardLayout';
import PrivateRoute from '../../components/Security/privateRoute';
import PromotePropertyModal from '../../components/Payment/PromotePropertyModal';
import MinifiedProperty from '../../components/Properties/MiniProperty';
import CustomTable from '../../components/Common/Table';
import CardsList from '../../components/Common/CardsList';
import Pagination from '../../components/Common/Pagination';

import { useBreakpoint } from '@a1-ict/gatsby/utils/breakpoint';

import { useDialog } from '@a1-ict/core/dialogSlice/useDialog';
import {
  updateMyProperties,
  fetchMyProperties,
  fetchMyPackageSlots,
  fetchMyPackageSlotDetails,
  fetchMyDetails,
} from '@a1-ict/fsbo/userSlice';
import {
  selectMyPackageSlotsDetails,
  selectMyPackageSlots,
  selectMyProperties,
} from '@a1-ict/fsbo/userSlice/selectors';
import ComponentToPrint from '../../components/Properties/PropertyPreview/ComponentToPrint';
import { hideLoader, showLoader } from '@a1-ict/core/dialogSlice';

import DeleteProperty from '../../components/Properties/MyProperties/DeletePropertyModal';

import { tableData } from '../../components/Properties/MyProperties/TableData';
import BuyPackageModal from '../../components/Payment/BuyPackageModal';
import { selectToast } from '@a1-ict/fsbo/notificationsSlice/selectors';
import { setToast } from '@a1-ict/fsbo/notificationsSlice';

import ArchiveProperty from '../../components/Properties/MyProperties/ArchievePropertyModal';

const MyProperties = () => {
  const [isPrintReady, setIsPrintReady] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showArchive, setShowArchive] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [selectedSlotDetails, setSelectedSlotDetails] = useState({});
  const [action, setAction] = useState(null);

  const urlPath = useLocation();

  const promoteModal = useDialog('promote');
  const buyPackageModal = useDialog('buyPackage');
  const breakpoints = useBreakpoint();
  const dispatch = useDispatch();

  const componentRef = useRef();
  const topOfTableRef = useRef(null);

  const { items: myProperties, total_pages } = useSelector(selectMyProperties);
  const { items: myPackageSlots } = useSelector(selectMyPackageSlots);

  const myPackageSlotDetails = useSelector(selectMyPackageSlotsDetails);
  const reduxToast = useSelector(selectToast);

  useEffect(() => {
    if (reduxToast?.showToast) {
      toast[reduxToast.type](reduxToast.message, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      dispatch(setToast(null));

      if (urlPath?.state?.refresh) {
        setTimeout(() => {
          fetchProperties();
        }, [5000]);
      }
    }
  }, [reduxToast]);

  const fetchProperties = () => {
    dispatch(fetchMyProperties({ queryParams: {} }));
  };

  useEffect(() => {
    fetchProperties();
    dispatch(fetchMyPackageSlots({ queryParams: {} }));
    dispatch(fetchMyPackageSlotDetails());
  }, []);

  useEffect(() => {
    if (isPrintReady) {
      setTimeout(() => {
        handlePrint();
      }, 1000);
    }
  }, [isPrintReady]);

  useEffect(() => {
    if (action && action === 'promote') promoteModal.open(true);
  }, [action]);

  const preparePrint = (property) => {
    dispatch(showLoader());
    setAction('print');
    setSelectedProperty(property);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
      setIsPrintReady(false);
      setSelectedProperty(null);
      setAction(null);
    },
    onBeforePrint: async () => {
      dispatch(hideLoader());
    },
  });

  const handlePromote = (property) => {
    setSelectedProperty(property);
    setAction('promote');
  };

  const handleDelete = (property) => {
    setShowDelete(true);
    setSelectedProperty(property);
  };

  const handleArchive = (property) => {
    setShowArchive(true);
    setSelectedProperty(property)
  }



  const onPaymentAttempted = (result, _, message = { success: '', error: '' }) => {
    if (result?.payload?.ok) {
      toast.success(message.success, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else
      toast.error(message.error, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    setTimeout(() => {
      fetchProperties();
      dispatch(fetchMyPackageSlots({ queryParams: {} }));
      dispatch(fetchMyPackageSlotDetails());
      dispatch(fetchMyDetails());
    }, [5000]);
  };

  const handlePageClick = (data) => {
    dispatch(fetchMyProperties({ queryParams: { CurrentPage: data.selected + 1 } }));
    topOfTableRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
  };

  return (
    <PrivateRoute>
      <DashboardLayout title="Моите обяви" childrenContainerClass="my_properties">
        <PromotePropertyModal
          propertyId={selectedProperty?.id}
          property={selectedProperty}
          isBuilding={selectedProperty?.is_building}
          resetAction={() => setAction(null)}
          onPaymentSuccess={onPaymentAttempted}
        />
        <BuyPackageModal
          onPaymentAttempted={onPaymentAttempted}
          setSelectedSlotDetails={setSelectedSlotDetails}
          selectedSlotDetails={selectedSlotDetails}
        />
        <DeleteProperty
          show={showDelete}
          setShowDelete={setShowDelete}
          propertyId={selectedProperty?.id}
        />
        <ArchiveProperty show={showArchive}
          setShowArchive={setShowArchive}
          propertyId={selectedProperty?.id}
        />
        <Row className="p-0 justify-content-end mb-2">
          <div className="d-flex align-items-stretch my_properties__buy_package">
            <div className="mr-3 d-flex align-items-center py-1 px-2">
              <div>Брой оставащи активни обикновени обяви:</div>
              <h6 className="color-default h5 mb-0">
                {myPackageSlotDetails?.unused_unpromoted_property_slots || 0}
              </h6>
            </div>
            <button className="btn animated" onClick={() => buyPackageModal.open(true)}>
              <span>Закупете пакет</span>
            </button>
          </div>
        </Row>
        <Row className="p-0 justify-content-end mb-2">
          <div className="my_properties__current_packages_container">
            {myPackageSlots?.length > 0 &&
              myPackageSlots.map((slot) => {
                return (
                  <div key={slot.id} className="d-flex my_properties__current_packages p-2">
                    <div className=" d-flex justify-content-center align-items-center  px-3">
                      <b>{slot.package_name}</b>
                    </div>
                    <div className=" d-flex justify-content-center align-items-center  px-3">
                      Валиден до: {slot.active_period_end}
                    </div>
                    <div
                      className=" d-flex justify-content-center align-items-center  px-3 color-default"
                      onClick={() => {
                        buyPackageModal.open(true);

                        setSelectedSlotDetails({
                          id: slot.id,
                          packageId: slot.package_id,
                          packageName: slot.package_name,
                          packagePrice: `${slot.package_price}лв.`,
                        });
                      }}
                    >
                      Удължи пакет
                    </div>
                  </div>
                );
              })}
          </div>
        </Row>

        <Row className="p-0">
          {myProperties.length > 0 ? (
            <div className=" flex-grow-1" ref={topOfTableRef}>
              {breakpoints.lg ? (
                <CardsList
                  columns={tableData(
                    'card',
                    handlePromote,
                    handleDelete,
                    preparePrint,
                    handleArchive,
                    promoteModal.open
                  )}
                  data={myProperties}
                />
              ) : (
                <Col xs={12} className="bg-white radiused p-0 ">
                  <CustomTable
                    columns={tableData(
                      'table',
                      handlePromote,
                      handleDelete,
                      preparePrint,
                      handleArchive,
                      promoteModal.open
                    )}
                    data={myProperties}
                  />
                </Col>
              )}
              <div className="pt-2  d-flex justify-content-center align-items-center ">
                {total_pages > 1 && (
                  <Pagination
                    handlePageClick={handlePageClick}
                    pageCount={total_pages}
                    forcePage={0}
                  />
                )}
              </div>
            </div>
          ) : (
            <h6>Все още нямате добавени имоти</h6>
          )}
        </Row>

        {action && action === 'print' && selectedProperty && (
          <div style={{ marginTop: '100vh' }}>
            <ComponentToPrint
              property={selectedProperty}
              ref={componentRef}
              imageProp={
                selectedProperty?.images[0]
                  ? {
                    url: `${process.env.GATSBY_BACKEND}${selectedProperty.images[0]?.url}`,
                    dynamicPage: true,
                  }
                  : {}
              }
              onMapLoaded={() => {
                setIsPrintReady(true);
              }}
            />
          </div>
        )}
      </DashboardLayout>
    </PrivateRoute>
  );
};

export default MyProperties;
