import React from 'react';
import { usePrivateFunction } from '../../Security/usePrivateFunction';
import { deleteProperty } from '@a1-ict/fsbo/propertySlice/requests';
import { fetchMyProperties } from '@a1-ict/fsbo/userSlice';
import { useDispatch } from 'react-redux';
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { toast } from 'react-toastify';

const DeleteProperty = ({ show, setShowDelete, propertyId }) => {
  const { secure } = usePrivateFunction();
  const dispatch = useDispatch();

  const handleDelete = (propertyId) => {
    secure(async () => {
      const result = await dispatch(deleteProperty({ id: propertyId }));
      if (result?.payload?.ok) {
        dispatch(fetchMyProperties({ queryParams: {} }));

        toast.success('Успешно изтриване на обявата!', {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        setShowDelete(false);
      } else {
        toast.error('Неуспешно изтриване на обявата!', {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        setShowDelete(false);
      }
    });
  };

  return (
    <Modal isOpen={show} toggle={() => setShowDelete(!show)} size="md">
      <ModalHeader toggle={() => setShowDelete(!show)}>Изтриване на обява</ModalHeader>
      <ModalBody className="p-0 d-flex flex-column justify-content-center">
        <Row className="justify-content-center my-3">
          <Col xs={12} sm={10} className="mb-3 d-flex flex-column justify-content-center px-4">
            При изтриване на обява, платените суми не се възстановяват. Сигурен ли сте, че искате да изтриете обявата?
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <button className="btn animated px-3 py-2" onClick={() => handleDelete(propertyId)}>
          <span className="">Изтрий</span>
        </button>
        <Button color="secondary" onClick={() => setShowDelete(false)}>
          Отказ
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DeleteProperty;
