import React, { useState } from 'react';
import { Link } from 'gatsby';

import { useBreakpoint } from '@a1-ict/gatsby/utils/breakpoint';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { FaCog } from '@react-icons/all-files/fa/FaCog';

const statuses = {
  ACTIVE: 'Активна',
  DRAFT: 'Чернова',
  INACTIVE: 'Неактивна',
  WAITING: 'Чакаща',
  ARCHIEVED: 'Архивирана',
  BLOCKED: 'Блокирана',
};

const DropDown = ({
  preparePrint,
  id,
  handleArchive,
  status,
  isPromoted,
  handlePromote,
  handleDelete,
  setSelectedProperty,
  property,
}) => {
  const breakpoints = useBreakpoint();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((dropdownOpen) => !dropdownOpen);

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      {breakpoints.lg ? (
        <div className="dropdown__toggler">
          <DropdownToggle>
            <FaCog fontSize={18}></FaCog>
          </DropdownToggle>
        </div>
      ) : (
        <DropdownToggle caret>Действия</DropdownToggle>
      )}
      <DropdownMenu right={true}>
        {status !== statuses.DRAFT && status !== statuses.BLOCKED && (
          <DropdownItem>
            <Link style={{ textDecoration: 'none' }} to={`/property/${id}`}>
              Виж обява
            </Link>
          </DropdownItem>
        )}

        <DropdownItem>
          <Link
            style={{ textDecoration: 'none' }}
            to={`/dashboard/add-property/?edit=${id}`}
            state={{ edit: true }}
          >
            Редактирай
          </Link>
        </DropdownItem>
        {status !== statuses.DRAFT && status !== statuses.BLOCKED && (
          <DropdownItem
            onClick={() => {
              handlePromote(property);
            }}
          >
            Обнови/Промотирай
          </DropdownItem>
        )}
        {status !== statuses.ARCHIEVED && status !== statuses.DRAFT && status !== statuses.BLOCKED && (
          <DropdownItem
            onClick={() => {
              handleArchive(property);
            }}
          >
            Архивирай
          </DropdownItem>
        )}
        {status !== statuses.DRAFT && status !== statuses.BLOCKED && (
          <DropdownItem onClick={() => preparePrint(property)}>Принтирай</DropdownItem>
        )}
        <DropdownItem
          onClick={() => {
            handleDelete(property);
          }}
        >
          Изтрий
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default DropDown;
